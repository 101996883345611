import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import '../sentry';

const useStyles = makeStyles(() => ({
  grid: {
    '@media (min-height:1200px)': {
      minHeight: 'auto',
      marginTop: '30px',
    },
  },
}));

export default function MyGrid(props) {
  const classes = useStyles();
  const { children } = props;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.grid}
    >
      {children}
    </Grid>
  );
}
