import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { getFile } from '../../../storage';
import WaveSurfer from '../../../components/WaveSurfer';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 'row',
  },
  wf: {
    flexGrow: 1,
  },
  play: {
    marginRight: '10px',
    borderRadius: '50%',
  },
}));

export default function Player({ review, nextReview, setPlayed }) {
  const classes = useStyles();

  const [player, setPlayer] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [audio, setAudio] = useState();
  const [nextAudio, setNextAudio] = useState();
  const [nextKey, setNextKey] = useState();

  useEffect(() => {
    setPlaying(false);
    setAudio(null);
    setPlayed(false);
    if (!review) return;
    const { key } = review;
    if (nextKey === key) {
      setAudio(nextAudio);
    } else {
      setLoading(true);
      getFile(key).then((raw) => {
        setAudio(raw);
        setLoading(false);
      });
    }
  }, [review]);

  useEffect(() => {
    if (!nextReview) return;
    const { key } = nextReview;
    getFile(key).then((raw) => {
      setNextAudio(raw);
      setNextKey(key);
    });
  }, [nextReview]);

  if (!review) {
    return null;
  }

  const play = () => {
    if (playing) {
      player.pause();
    } else {
      player.play();
      setTimeout(() => {
        setPlayed(true);
      }, 1000);
    }
  };

  return (
    <Grid item className={classes.root}>
      <Button onClick={play} color="primary" className={classes.play}>
        {loading ? <CircularProgress fontSize="large" /> : (playing ? <StopIcon fontSize="large" /> : <PlayArrowIcon fontSize="large" />)}
      </Button>
      <div className={classes.wf}>
        <WaveSurfer
          audio={audio}
          id="wfplayer"
          setPlayer={setPlayer}
          setPlaying={setPlaying}
        />
      </div>

    </Grid>
  );
}
