import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Grid, makeStyles } from '@material-ui/core';
import Authenticated from '../_authenticated';
import { readNextSentence, getLocalMetadata } from '../../db';
import ReviewBox from './ReviewBox';
import SideBar from './Sidebar';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('lg')]: {
      paddingRight: '100px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      paddingRight: '280px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px',
      paddingRight: '16px',
    },
    [theme.breakpoints.down('xs')]: {},
  },
}));

export default function App() {
  const classes = useStyles();
  const [review, setReview] = useState();
  const [nextData, setNextData] = useState({});
  const [lastId, setLastId] = useState();
  const [reload, setReload] = useState();
  const [loading, setLoading] = useState(true);
  const [addReview, setAddReview] = useState(null);
  const meta = getLocalMetadata();

  useEffect(() => {
    setLoading(true);
    readNextSentence().then((r) => {
      setReview(r.review);
      setLastId(r.lastId);
      setLoading(false);
    });
  }, [reload]);

  const handleOldReviewClick = (oldReview) => {
    setNextData();
    setReview(oldReview);
  };

  useEffect(() => {
    if (!review) {
      return;
    } // ignore initial call
    setNextData();
    readNextSentence(review).then((r) => {
      setNextData(r);
    });
  }, [review]);

  const goNext = async (newReview) => {
    let data = nextData;
    if (newReview) {
      setAddReview(newReview);
    }
    if (!data) {
      setLoading(true);
      data = await readNextSentence(review);
      setLoading(false);
    }
    setReview(data.review);
    setLastId(lastId);
  };

  return (
    <Authenticated>
      <Container maxWidth="md" className={classes.container}>
        <Box my={4} />

        <Grid container>
          <ReviewBox
            meta={meta}
            review={review}
            goNext={goNext}
            nextReview={nextData && nextData.review}
            loading={loading}
          />
        </Grid>
        <SideBar
          review={review}
          addReview={addReview}
          meta={meta}
          onReviewClick={handleOldReviewClick}
        />
      </Container>
    </Authenticated>
  );
}
