/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import {
  FormControl, Grid, InputLabel, makeStyles, Select,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Context from '../Context';
import {
  createOrLoadMetadata, getGroupId, getLocalMetadata, readCampaigns,
} from '../db';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '100%',
    textAlign: 'center',
  },
  selectbox: {
    width: '100%',
  },
}));

export default function MetadataForm() {
  const classes = useStyles();
  const [sending, setSending] = useState();
  const [campaigns, setCampaigns] = useState([]);
  const { group } = useContext(Context);
  const navigate = useNavigate();
  const { handleSubmit, reset, control } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (d) => {
    if (!d.campaign) {
      enqueueSnackbar('Please select a campaign', { variant: 'error' });
    }
    setSending(true);
    const data = { ...d };
    createOrLoadMetadata({ ...data, groupId: getGroupId() }).then(() => {
      navigate('/app');
    });
  };

  useEffect(() => {
    const metadata = getLocalMetadata();
    reset(metadata);
    readCampaigns().then((data) => {
      setCampaigns(data);
    });
  }, []);

  return (
    <form id="metadata-form" className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <>
          <Grid item sm={6} xs={12}>
            <Controller
              name="firstname"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={group && group.firstname ? group.firstname : 'First Name'}
                  autoComplete={group && group.firstname ? group.firstname : 'firstname'}
                  autoFocus
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Controller
              name="lastname"
              control={control}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={group && group.lastname ? group.lastname : 'Last Name'}
                  autoComplete={group && group.lastname ? group.lastname : 'lastname'}
                  {...field}
                />
              )}
            />
          </Grid>
        </>
        <Grid item sm={4} xs={12}>
          <Controller
            name="campaign"
            control={control}
            render={({ field }) => (
              <FormControl className={classes.selectbox}>
                <InputLabel htmlFor="campaign">Campaign</InputLabel>
                <Select
                  native
                  required
                  inputProps={{
                    name: 'campaign',
                    id: 'campaign',
                  }}
                  {...field}
                >
                  <option aria-label="None" value="" />
                  {campaigns.map((campaign) => (<option key={campaign.campaign} value={campaign.campaign}>{campaign.campaign}</option>))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <div className={classes.submit}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={sending}
          id="metadata-next"
        >
          Next
        </Button>
      </div>
    </form>
  );
}
