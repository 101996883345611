/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import StatusIcon from './StatusIcon';

const useStyles = makeStyles(() => ({
  sentence: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    maxHeight: '72px',
    cursor: 'pointer',
  },
  searchIcon: {
    right: '5px',
    position: 'absolute',
    top: '5px',
  },
  searchBox: {
    width: '100%',
    padding: '5px',
  },
  listRow: {
    cursor: 'default',
  },
  starIcon: {
    minWidth: '30px',
    cursor: 'pointer',
  },
  listIcon: {
    minWidth: '33px',
    cursor: 'default',
  },
}));

export default function FileList({ reviews, onReviewClick }) {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    const nr = query
      ? reviews.filter((r) => r.label.toLowerCase().includes(query.toLowerCase()))
      : reviews;
    setRows(nr);
  }, [reviews]);

  // const onSearch = useCallback((e) => {
  //   setQuery(e.target.value);
  // }, []);

  const Row = ({ index, style }) => {
    const rec = rows[index];
    return (
      <ListItem style={style} className={classes.listRow} button key={rec.key}>
        <ListItemIcon className={classes.listIcon}>
          <StatusIcon status={rec.status} />
        </ListItemIcon>
        <ListItemText
          className={classes.sentence}
          primary={rec.label || rec.key?.split('/')[2]}
          onClick={() => {
            onReviewClick(rec);
          }}
        />
      </ListItem>
    );
  };

  return (
    <>
      <Divider />
      <AutoSizer>
        {({ height, width }) => (
          <List
            className="List"
            height={height - 60}
            itemCount={rows.length}
            itemSize={100}
            width={width}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    </>
  );
}
