import { useCallback, useEffect, useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import sha256 from 'crypto-js/sha256';
import sentry from './sentry';
import config from './config';

Amplify.configure(config.awsmobile);

export const useAuth = () => {
  const [user, setUser] = useState();
  const [signing, setSigning] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    if (!user && !logout) {
      setError(false);
      setLoading(true);
      Auth.currentAuthenticatedUser().then((u) => {
        if (u) {
          setUser(u);
        }
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        // sentry(err);
        // setError(err.message);
      });
    }
  }, [user]);

  const signIn = useCallback(async (groupId) => {
    setError(false);
    setLogout(false);
    setSigning(true);
    setLoading(true);
    try {
      const password = sha256(groupId).toString();
      let u = await Auth.signIn(
        groupId,
        password,
      );

      if (u.challengeName === 'NEW_PASSWORD_REQUIRED') {
        u = await Auth.completeNewPassword(u, password);
      }
      if (u) {
        setUser(u);
        setSigning(false);
        window.localStorage.setItem('groupId', groupId);
      } else {
        setError("Group ID doesn't exist");
      }
      setLoading(false);
      return u;
    } catch (err) {
      if (err.code === 'UserNotFoundException') {
        setError("Group ID doesn't exist");
      } else {
        sentry('error signing up:', err);
        setError(err.message);
      }
      setSigning(false);
      setLoading(false);
    }
  }, [setLoading, setUser, setSigning]);

  const signOut = useCallback((cb) => {
    setError(false);
    setLoading(true);
    setLogout(true);
    setUser();
    Auth.signOut().then(() => {
      cb && cb();
      setLoading(false);
    }).catch((err) => setError(err.message));
  }, [setUser]);

  return {
    user, signIn, signOut, signing, loading, error,
  };
};

export const getAuth = () => ({
  groupId: window.localStorage.getItem('groupId'),
});
