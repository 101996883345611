/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useState } from 'react';
import {
  AppBar, Button, makeStyles, Toolbar,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useNavigate } from 'react-router-dom';
import Context from '../Context';
import logo from '../logo.png';

const useStyles = makeStyles((theme) => ({
  topbar: {
    background: 'transparent!important',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '60px',
      marginRight: '60px',
    },
  },
  title: {
    flexGrow: 1,
  },
  button: {
    color: 'black',
    minWidth: '10px',
  },
}));

export default function TopBar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user, signOut } = useContext(Context);
  const [showLogout, setShowLogout] = useState(true);

  useEffect(() => {
    // if this is inside iframe
    if (window.self !== window.top) {
      setShowLogout(false);
    }
  });

  const logout = (e) => {
    e.preventDefault();
    signOut(() => {
      navigate('/');
    });
  };

  return (
    <AppBar position="absolute" className={classes.topbar}>
      <Toolbar>
        <a title="Sanas" href="https://sanas.ai" target="_blank" rel="noreferrer">
          <img src={logo} width="71" height="60" alt="Sanas.ai" />
        </a>

        <div className={classes.title} />
        {user && (
        <>
          {' '}
          {showLogout && (
            <>
              |

              <Button onClick={logout} title="Sign out" className={classes.button}>
                <ExitToAppIcon />
                {' '}
              </Button>
            </>
          )}
        </>
        )}
      </Toolbar>
    </AppBar>
  );
}
