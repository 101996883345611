import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import Context from '../Context';

export default function Index() {
  const navigate = useNavigate();
  const { user } = useContext(Context);

  if (user) {
    navigate('/metadata');
    return null;
  }

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <LoginForm />
      </Box>
    </Container>
  );
}
