import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { red } from '@material-ui/core/colors';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import { updateReview } from '../../../db';
import { copyVerified } from '../../../storage';

const SOURCE_OPTIONS = [
  'Multiple speakers',
  'Word inaccuracy (missing / added incorrect words)',
  'Unintentional hesitations / stutters in speech',
  'Severe mispronunciations',
  'High background noise',
  'Reverberation',
  'Mic pops',
  'Clipped speech (cut off)',
  'Humming',
];

const TARGET_OPTIONS = [
  'Multiple speakers',
  'Word Inaccuracy (missing / added incorrect words)',
  'Unintentional hesitations / stutters in speech',
  'Severe mispronunciations',
  'Background noise',
  'Reverberation',
  'Mic Pops',
  'Clipped speech (cut off)',
  'Humming',
  'Breaths',
  'Mouse Clicks',
  'Digital Clicks',
];

const ASR_OPTIONS = [
  'High Background noise',
  'Low intelligibility',
  'No leading / trailing silence',
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    placeContent: 'flex-end',
  },
  formControl: {
    width: '300px',
    marginTop: theme.spacing(1),
  },
  other: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
  },
  button: {
    margin: theme.spacing(1),
  },
  submitBar: {
    display: 'flex',
    placeContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
}));

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

export default function ReviewBar({ goNext, review, played, loading, meta }) {
  const classes = useStyles();

  const [status, setStatus] = useState('U');
  const [reason, setReason] = useState([]);
  const [other, setOther] = useState('');
  const [transcribe, setTranscribe] = useState('');
  const [showOther, setShowOther] = useState(false);

  const OPTIONS = meta?.isAsrAgent ? ASR_OPTIONS : (meta?.isTargetAgent ? TARGET_OPTIONS : SOURCE_OPTIONS);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setShowOther(false);
    if (!review) {
      setStatus('U');
      setReason([]);
      setOther('');
      setTranscribe('');
      return;
    }

    setReason(review.reason || []);
    setStatus(review.status || 'U');
    setOther(review.other);
    setTranscribe(review.transcribe || '');
    if (review.other) {
      setShowOther(true);
    }
  }, [review]);

  const handleStatus = (s) => {
    setStatus(s);

    if (s === 'P' && other) {
      setOther('');
      setShowOther(false);
    }

    if (s === 'P' && reason?.length) {
      setReason([]);
    }
  };

  const handleReason = (event) => {
    const opt = event.target.value;
    if (opt.includes('other')) {
      setShowOther(true);
    } else {
      setShowOther(false);
    }
    setReason(opt);
  };

  const handleOther = (event) => {
    setOther(event.target.value);
  };

  const handleTranscribe = (event) => {
    setTranscribe(event.target.value);
  };

  let FailButton = Button;
  let failCheck = false;
  if (status === 'F') {
    FailButton = RedButton;
    failCheck = true;
  }

  const submit = () => {
    const r = {
      ...review,
      transcribe,
      status,
      reason,
      other,
    };
    if (!meta?.isAsrAgent) {
      copyVerified(r);
    }
    updateReview(r)
      .then(() => {
        goNext(r);
      })
      .catch((err) => {
        enqueueSnackbar('Couldnt process your request, please try again', { variant: 'error' });
        console.error(err);
      });
  };

  return (
    <>
      <Grid>
        <TextField
          multiline
          maxRows={4}
          onPaste={(e) => {
            e.preventDefault();
          }}
          onChange={handleTranscribe}
          className={classes.other}
          id="outlined-basic"
          label="Transcribe the utterance"
          variant="outlined"
          value={transcribe}
          disabled={!played}
        />
      </Grid>
      <Grid className={classes.root}>
        <Button
          className={classes.button}
          variant={status === 'P' ? 'contained' : 'outlined'}
          color="secondary"
          onClick={() => handleStatus('P')}
          title="Pass"
          disabled={!played}
        >
          <CheckIcon />
          {' '}
          Pass
        </Button>

        <FailButton
          variant="outlined"
          onClick={() => handleStatus('F')}
          className={classes.button}
          title="Fail"
          disabled={!played}
        >
          <CloseIcon />
          {' '}
          Fail
        </FailButton>

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            {failCheck ? 'Reason' : ''}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={reason || []}
            multiple
            onChange={handleReason}
            label={failCheck ? 'Reason' : ''}
            disabled={!failCheck}
            renderValue={(selected) => selected.join(', ')}
          >
            {OPTIONS.map((opt) => (
              <MenuItem key={opt} value={opt}>
                <Checkbox checked={reason.indexOf(opt) > -1} />
                <ListItemText primary={opt} />
              </MenuItem>
            ))}

            <MenuItem value="other">
              <Checkbox checked={reason.indexOf('other') > -1} />
              <ListItemText primary="Other issue(s)" />
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {showOther && (
        <Grid xs={12}>
          <TextField
            multiline
            maxRows={4}
            onChange={handleOther}
            className={classes.other}
            id="outlined-basic"
            label="Explain the issue"
            variant="outlined"
            value={other}
          />
        </Grid>
      )}

      <Grid className={classes.submitBar}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={submit}
          title="Submit"
          disabled={loading || (meta?.isAsrAgent && status === 'P' && !transcribe) || (!meta?.isAsrAgent && !transcribe) || status === 'U' || (status === 'F' && !reason?.length)}
        >
          Submit
        </Button>
      </Grid>
    </>
  );
}
