/* eslint-disable consistent-return */
/* eslint-disable no-empty */
import { useSnackbar } from 'notistack';
import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import WS from 'wavesurfer.js';
import Cursor from './Cursor';

export default function Wavesurfer({
  id, audio, setPlayer, setPlaying, colorWave, colorProgress, height, forceReload,
}) {
  const wavesurfer = useRef();

  useEffect(() => {
    const params = {
      container: document.getElementById(id),
      waveColor: colorWave || 'violet',
      progressColor: colorProgress || 'purple',
      height: height || 65,
      plugins: [
        Cursor.create({
          showTime: true,
          opacity: 1,
          customShowTimeStyle: {
            'background-color': '#000',
            color: '#fff',
            padding: '2px',
            'font-size': '10px',
          },
        }),
      ],
    };
    const wc = WS.create(params);
    wavesurfer.current = wc;

    wc.on('pause', () => {
      wavesurfer.current.params.container.style.opacity = 0.7;
      setPlaying && setPlaying(false);
    });

    wc.on('play', () => {
      wc.params.container.style.opacity = 1;
      setPlaying && setPlaying(true);
    });

    setPlayer && setPlayer(wavesurfer.current);

    return (() => {
      try {
        wc.microphone.stop();
      } catch (err) {
      }
      wc.destroy();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceReload]);

  useEffect(() => {
    if (wavesurfer.current) {
      if (audio) {
        wavesurfer.current.load(audio);
      } else if (wavesurfer.current.backend) wavesurfer.current.empty();
    }
  }, [audio]);

  if (!id) return null;

  return (<div id={id} />);
}
