import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import theme from './theme';
import Context from './Context';
import { useAuth } from './auth';
import Grid from './pages/_grid';
import Metadata from './pages/Metadata';
import Main from './pages/app';
import Login from './pages/Login';
import './sentry';
import TopBar from './components/TopBar';

export default function MyApp() {
  const { user, signOut, signIn, signing, loading, error } = useAuth();

  return (
    <>
      <Router>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <Context.Provider
              value={{
                signIn,
                user,
                signOut,
                signing,
                loading,
                error,
              }}
            >
              <CssBaseline />
              <TopBar />
              <Grid>
                <Routes>
                  <Route path="/metadata" element={<Metadata />} />
                  <Route path="/app" element={<Main />} />
                  <Route path="/" element={<Login />} />
                </Routes>
              </Grid>
            </Context.Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </Router>
    </>
  );
}
