/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Context from '../../../Context';
import config from '../../../config';
import { updateTimer } from '../../../db';

export default function Timer({ review, meta, total }) {
  const { user, signOut } = useContext(Context);
  const [currentReview, setCurrentReview] = useState(review);
  const [timer, setTimer] = useState(0);
  const [reviewChanged, setReviewChanged] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (!meta) return;
    let t = meta.timer || timer;
    setReviewChanged(t);
    setTimer(t);
    const interval = setInterval(() => {
      if (!review) return;
      t += 1;
      setTimer(t);
    }, 1000);
    return () => clearInterval(interval);
  }, [meta]);

  useEffect(() => {
    if (user && (timer - reviewChanged) * 1000 > config.idleTime) {
      enqueueSnackbar('Too much time without activity, redirecting to initial page', { variant: 'error' });
      signOut(() => {
        navigate('/');
      });
    }
  }, [timer, user]);

  useEffect(() => {
    if (review === currentReview || !review) return;
    if (currentReview) {
      updateTimer(timer, total);
      setReviewChanged(timer);
    }
    setCurrentReview(review);
  }, [review]);

  const mins = Math.floor(timer / 60);

  const dhours = Math.floor(mins / 60).toString().padStart(2, '0');
  const dmins = (mins % 60).toString().padStart(2, '0');
  const dsecs = (timer % 60).toString().padStart(2, '0');

  const clock = `${dhours}:${dmins}:${dsecs}`;

  return <span>{clock}</span>;
}
