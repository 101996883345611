export default {
  debug: false,
  region: 'us-east-1',
  bucket: 'datagatheringapp1a097af314bb4bffb92f960eaba5f9f11141-quality',
  table: 'webqa_reviews',
  tableAsr: 'asr_transcriptions',
  tableIndex: 'campaignStatus-key-index',
  tableHashIndex: 'userHash-reviewedAt-index',
  tableMetausers: 'webqa_usersdata',
  tableCampaigns: 'webqa_campaigns',
  tableAsrCampaigns: 'asr_campaigns',
  idleTime: 5 * 60 * 1000,
  awsmobile: {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:b64d785d-68a3-467a-bfdd-a561e280ccef',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_JHJ1Nvo6M',
    aws_user_pools_web_client_id: '7d6jhvbtafsclho1trg05md472',
    oauth: {},
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: [
      'SMS',
    ],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: [
      'PHONE_NUMBER',
    ],
  },
};
