/* eslint-disable import/prefer-default-export */
import {
  DynamoDBClient,
  paginateQuery,
  paginateScan,
} from '@aws-sdk/client-dynamodb';
import {
  DynamoDBDocumentClient, UpdateCommand, GetCommand, QueryCommand,
} from '@aws-sdk/lib-dynamodb';
import sha256 from 'crypto-js/sha256';

import { Auth } from 'aws-amplify';
import config from './config';

const marshallOptions = {
  // Whether to automatically convert empty strings, blobs, and sets to `null`.
  convertEmptyValues: false, // false, by default.
  // Whether to remove undefined values while marshalling.
  removeUndefinedValues: true, // false, by default.
  // Whether to convert typeof object to map attribute.
  convertClassInstanceToMap: false, // false, by default.
};

const unmarshallOptions = {
  // Whether to return numbers as a string instead of converting them to native JavaScript numbers.
  wrapNumbers: false, // false, by default.
};

const translateConfig = { marshallOptions, unmarshallOptions };

let dynamodbClient;
let dynamodb;
let timeout;
const TIMEOUT_CONN = 10 * 1000; // 10 sec

let credentialsBuffer;
export const getCredentials = async () => {
  if (!credentialsBuffer || Date.now() > timeout + TIMEOUT_CONN) {
    timeout = Date.now();
    await Auth.currentAuthenticatedUser();
    credentialsBuffer = await Auth.currentCredentials();
  }
  return credentialsBuffer;
};

const getDynamoDB = async () => {
  const credentials = await getCredentials();
  dynamodb = new DynamoDBClient({
    region: config.region,
    credentials,
  });
  dynamodbClient = DynamoDBDocumentClient.from(dynamodb, translateConfig);
  return dynamodbClient;
};

export const getGroupId = () => window.localStorage.getItem('groupId');

export const getLocalMetadata = () => {
  const groupId = getGroupId();
  const d = JSON.parse(localStorage.getItem(`metadataV2_${groupId}`));
  return d || {};
};

export const storeMetadata = async (data) => {
  const groupId = getGroupId();

  const db = await getDynamoDB();

  const response = await db.send(new UpdateCommand({
    TableName: config.tableMetausers,
    Key: { userHash: data.hash, groupId },
    UpdateExpression: 'SET firstname = :firstname, lastname = :lastname, campaign = :campaign',
    ExpressionAttributeValues: {
      ':firstname': data.firstname,
      ':lastname': data.lastname || '',
      ':campaign': data.campaign || '',
    },
    ReturnValues: 'ALL_NEW',
  }));

  return response;
};

export const createOrLoadMetadata = async (d) => {
  const data = { ...d };
  const groupId = getGroupId();

  const arr = [
    data.groupId,
    data.firstname.toLowerCase(),
    data.lastname.toLowerCase(),
    data.campaign,
  ];

  data.hash = sha256(arr.join('-')).toString();

  const { isTargetAgent, isAsrAgent } = JSON.parse(localStorage.getItem(`metadataV2_${groupId}`));

  data.isTargetAgent = isTargetAgent;
  data.isAsrAgent = isAsrAgent;

  const metaUser = await storeMetadata(data);
  localStorage.setItem(`metadataV2_${groupId}`, JSON.stringify({ ...data, ...metaUser.Attributes }));
};

export const readMetauser = async () => getLocalMetadata();

export const updateTimer = async (timer, total) => {
  const groupId = getGroupId();
  const { hash } = getLocalMetadata();

  const db = await getDynamoDB();

  const params = {
    TableName: config.tableMetausers,
    Key: { userHash: hash, groupId },
    UpdateExpression: 'SET timer = :timer, totalReviewed = :totalReviewed',
    ExpressionAttributeValues: {
      ':timer': timer,
      ':totalReviewed': total,
    },
  };

  const response = await db.send(new UpdateCommand(params));

  return response;
};

/** Sentence logic */

export const readNextSentence = async (pReview) => {
  const db = await getDynamoDB();
  const { campaign, isAsrAgent } = getLocalMetadata();

  const updateStatus = `${campaign}#U`;

  let params = {
    ProjectionExpression: 'groupId, campaign, campaignStatus, #k, readAt',
    TableName: isAsrAgent ? config.tableAsr : config.table,
    IndexName: config.tableIndex,
    ExpressionAttributeNames: {
      '#k': 'key',
    },
    Limit: 1,
  };

  if (pReview) {
    params = {
      ...params,
      KeyConditionExpression: 'campaignStatus = :s AND #k > :k',
      ExpressionAttributeValues: {
        ':s': updateStatus,
        ':k': pReview.key,
      },
    };
  } else {
    params = {
      ...params,
      KeyConditionExpression: 'campaignStatus = :s',
      ExpressionAttributeValues: {
        ':s': updateStatus,
      },
    };
  }

  const data = await db.send(new QueryCommand(params));

  const review = data.Items[0];

  if (!review) return {};

  if (review.readAt + config.idleTime > Date.now()) {
    console.log('review is blocked, reading next', review.key);
    return readNextSentence(review);
  }

  review.status = review.campaignStatus.split('#')[1];

  params = {
    TableName: isAsrAgent ? config.tableAsr : config.table,
    Key: { groupId: review.groupId, key: review.key },
    UpdateExpression: 'SET readAt = :readAt',

    ExpressionAttributeValues: {
      ':readAt': Date.now(),
    },
    ReturnValues: 'ALL_NEW',
  };

  await db.send(new UpdateCommand(params));

  return { review, lastId: data.LastEvaluatedKey && data.LastEvaluatedKey.id };
};

export const updateReview = async ({
  groupId, key, status, reason, other, transcribe,
}) => {
  const db = await getDynamoDB();
  const { hash, campaign, isAsrAgent } = getLocalMetadata();

  const campaignStatus = `${campaign}#${status}`;
  const params = {
    TableName: isAsrAgent ? config.tableAsr : config.table,
    Key: { groupId, key },
    UpdateExpression: 'SET campaignStatus = :s, reason = :reason, #o = :ot, userHash = :h, reviewedAt = :u, transcribe = :t',
    ExpressionAttributeValues: {
      ':s': campaignStatus,
      ':reason': reason || [],
      ':ot': other || '',
      ':h': hash,
      ':u': Date.now(),
      ':t': transcribe,
    },
    ExpressionAttributeNames: {
      '#o': 'other',
    },
    ReturnValues: 'ALL_NEW',
  };
  const data = await db.send(new UpdateCommand(params));
  return data.Attributes;
};

export const readReviewed = async () => {
  await getDynamoDB();
  const { hash, isAsrAgent } = getLocalMetadata();

  const params = {
    TableName: isAsrAgent ? config.tableAsr : config.table,
    IndexName: config.tableHashIndex,
    KeyConditionExpression: 'userHash = :h',
    ExpressionAttributeValues: {
      ':h': { S: hash },
    },
  };

  const cfg = {
    client: dynamodb,
    pageSize: 100,
  };

  let all = [];
  const paginator = paginateQuery(cfg, params);
  for await (const page of paginator) {
    all = all.concat(page.Items);
  }

  return all;
};

export const readCampaigns = async () => {
  const groupId = await getGroupId();
  const db = await getDynamoDB();

  const data = await db.send(new GetCommand({
    TableName: 'webqa_groups',
    Key: {
      groupId,
    },
  }));

  let assignedCampaigns = [];

  if (data.Item?.campaigns) {
    assignedCampaigns = data.Item.campaigns.split(',');
  }

  const localMetadata = JSON.parse(localStorage.getItem(`metadataV2_${groupId}`));

  const updatedLocalMetadata = {
    ...localMetadata,
    isAsrAgent: !!data.Item?.isAsrAgent,
    isTargetAgent: !!data.Item?.isTargetAgent,
  };

  localStorage.setItem(`metadataV2_${groupId}`, JSON.stringify(updatedLocalMetadata));

  const params = {
    TableName: updatedLocalMetadata.isAsrAgent ? config.tableAsrCampaigns : config.tableCampaigns,
  };

  const cfg = {
    client: dynamodb,
    pageSize: 100,
  };

  let all = [];
  const paginator = paginateScan(cfg, params);
  for await (const page of paginator) {
    all = all.concat(page.Items);
  }

  all = all.map((c) => ({
    campaign: c.campaign.S,
    createdAt: parseInt(c.createdAt.N),
  }));

  if (assignedCampaigns.length) {
    all = all.filter((c) => assignedCampaigns.includes(c.campaign));
  }
  all.sort((a, b) => {
    if (a.campaign < b.campaign) {
      return -1;
    }
    if (a.campaign > b.campaign) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  return all;
};
