/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { green, red } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export default function StatusIcon({ status, title }) {
  let icon;
  switch (status) {
    case 'P':
      icon = (<CheckIcon style={{ color: green[500] }} />);
      break;
    case 'F':
      icon = (<ErrorOutlineIcon style={{ color: red[500] }} />);
      break;
    default:
      return null;
  }
  return <span title={title}>{icon}</span>;
}
