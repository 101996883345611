import ids from '../../ids.json';

export default function getSentence(key) {
  if (!key || !key.split) return null;
  const parts = key.split('/');
  const filename = parts[parts.length - 1];
  const filebase = filename.split('.')[0];
  const fileparts = filebase.split('-');
  const corpus = fileparts[0];
  const id = fileparts[1];
  const sentence = ids[corpus]?.[id] || 'ALERT: Prompt not found. Please reach out to the tech team.';

  return sentence;
}
