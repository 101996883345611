/* eslint-disable */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { Box, Breadcrumbs, CircularProgress, Grid, Typography } from '@material-ui/core';
import getSentence from '../sentence';
import Player from './Player';
import ReviewBar from './ReviewBar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  sentenceBox: {
    background: '#F9F8F8',
    borderRadius: '11px',
    boxShadow: '0px 0px 4px 0px #00000040',
    minWidth: '543px',
    paddingTop: '30px',
    paddingBottom: '30px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
    },
  },
  sentenceLabel: {
    fontSize: '20px',
  },
  sentenceId: {
    fontSize: '14px',
    fontStyle: 'italic',
  },
  fileLine: {
    float: 'left',
    height: '18px',
  },
}));

export default function ReviewBox({ review, goNext, nextReview, loading, meta }) {
  const classes = useStyles();
  const [played, setPlayed] = useState(false);

  let file;
  let user;
  if (review) {
    const parts = review.key.split('/');
    file = parts[parts.length - 1];
    user = parts[parts.length - 2].split('__')[0];
  }

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <PersonIcon />
          <Typography color="textPrimary">{meta?.isAsrAgent ? file : user}</Typography>
        </Breadcrumbs>
      </Grid>
      {!meta?.isAsrAgent &&
        <Grid item xs={12}>
          <Box className={classes.sentenceBox}>
            <div className={classes.sentenceLabel}>
              {loading ? <CircularProgress /> : review ? getSentence(review && review.key) : 'No more reviews available'}
            </div>
            <div className={classes.sentenceId}>
              <InsertDriveFileIcon className={classes.fileLine} /> <div className={classes.fileLine}>{file}</div>
            </div>
          </Box>
        </Grid>
      }
      <Grid item xs={12}>
        <Player review={review} nextReview={nextReview} setPlayed={setPlayed} />

        <ReviewBar review={review} goNext={goNext} played={played} loading={loading} meta={meta} />
      </Grid>
    </Grid>
  );
}
