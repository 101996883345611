/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Drawer, Hidden, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Context from '../../../Context';
import FileList from './FileList';
import { readReviewed } from '../../../db';
import getSentence from '../sentence';
import Timer from './Timer';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: '85%',
    [theme.breakpoints.up('xs')]: {
      width: drawerWidth,
    },
  },
  drawerPaper: {
    width: '85%',
    overflowX: 'hidden',
    [theme.breakpoints.up('xs')]: {
      width: '240px',
      marginTop: '60px',
      height: 'calc(100vh - 35px)',
    },
    [theme.breakpoints.up('md')]: {
      width: '260px',
    },
  },
  menuButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
}));

const StatusSideBar = ({ addReview, review, meta, onReviewClick }) => {
  const { group, loading: userLoading } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState([]);

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (!reviews) return;
    setLoading(true);
    readReviewed().then((res) => {
      const allReviews = res.map((r) => ({
        groupId: r.groupId.S,
        key: r.key.S,
        status: r.campaignStatus.S.split('#')[1],
        label: !meta?.isAsrAgent ? getSentence(r.key.S) : r.transcribe.S,
        reason: (r.reason?.L || r.reason?.S ? [r.reason.S] : []).filter((k) => k),
        other: r.other.S,
        transcribe: r.transcribe.S,
      }));
      allReviews.sort((a, b) => !a.key.localeCompare(b.key));
      setReviews(allReviews);
      setLoading(false);
    });
    // do something
  }, []);

  useEffect(() => {
    if (addReview) {
      const restOfTheReviews = reviews.filter((rev) => rev.key !== addReview.key);
      setReviews([{ ...addReview, label: !meta?.isAsrAgent ? getSentence(addReview.key) : addReview.transcribe }, ...restOfTheReviews]);
    }
  }, [addReview]);

  if (userLoading) return null;

  const drawer = (
    <>
      <Box display="flex" flexDirection="row" ml={1} mr={2}>
        <Box flexGrow={1}>
          <Timer review={review} meta={meta} total={reviews.length} />
        </Box>
        <Box>Total: {reviews.length}</Box>
      </Box>
      {loading ? (
        <Box display="block" textAlign="center" mt={3}>
          <CircularProgress />
        </Box>
      ) : (
        <FileList reviews={reviews} onReviewClick={onReviewClick} />
      )}
    </>
  );

  return (
    <>
      <Hidden mdUp implementation="css">
        <Button
          color="primary"
          variant="contained"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </Button>
        <Drawer
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          className={classes.drawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="right"
          variant="permanent"
          open
          className={classes.drawer}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};

export default StatusSideBar;
