import { GetObjectCommand, S3, CopyObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';
import config from './config';
import { getCredentials, getLocalMetadata } from './db';

function readAllChunks(readableStream, mimeType) {
  return new Promise((resolve) => {
    const reader = readableStream.getReader();
    const chunks = [];

    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          return resolve(new Blob(chunks, { type: mimeType }));
        }
        chunks.push(value);
        return pump();
      });
    }

    return pump();
  });
}

const getS3 = async () => {
  const credentials = await getCredentials();
  return new S3({
    region: config.region,
    credentials,
  });
};

export const getFile = async (key) => {
  const s3 = await getS3();

  const params = {
    Bucket: config.bucket,
    Key: key,
    Download: true,
  };

  const file = await s3.send(new GetObjectCommand(params));
  const body = await readAllChunks(file.Body, file.ContentType);
  return URL.createObjectURL(body);
};

export const copyVerified = async ({ key, status }) => {
  const { campaign } = getLocalMetadata();
  const s3 = await getS3();

  const newStatus = status === 'P' ? 'verified/' : 'failed/';
  const destKey = key.replace('private/', newStatus).replace('validated/', newStatus);

  const params = {
    Bucket: config.bucket,
    CopySource: `${config.bucket}/${key}`,
    Key: destKey,
  };

  try {
    await s3.send(new CopyObjectCommand(params));
  } catch (err) {
    if (err.Code === 'NoSuchKey') {
      const ns = status === 'P' ? 'verified/' : 'failed/';
      const np = status === 'P' ? 'failed/' : 'verified/';
      const dk = key.replace('private/', ns).replace('validated/', ns);
      const sk = key.replace('private/', np).replace('validated/', np);

      const newParams = {
        Bucket: config.bucket,
        CopySource: `${config.bucket}/${sk}`,
        Key: dk,
      };

      try {
        await s3.send(new CopyObjectCommand(newParams));
      } catch (errq) {
        console.error(errq);
      }
    }
  }

  if (status === 'F' && !campaign.includes('-S')) {
    // delete the failed validated utterance for the taget speakers
    await s3.send(new DeleteObjectCommand({
      Bucket: config.bucket,
      Key: key,
    }));
  }
};
